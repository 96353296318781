import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Alex Jones inteviews David Icke"/>
        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <hr className="divider light my-4"/>
                <p className="text-white-50 mb-4">
                  David Icke has been warning for nearly 30 years of a coming global Orwellian state in which a tiny few would enslave humanity through control of finance, government, media and a military-police Gestapo overseeing 24/7 surveillance of a microchipped population.He has said that ‘physical’ reality is an illusion and what we think is the ‘world’ is a holographic simulation or ‘Matrix’ created by a non-human force to entrap human perception in ongoing servitude.They called him ‘crazy’, ‘insane’, a ‘lunatic’, and he was subjected to decades of ridicule, dismissal and abuse. Oh, but how things change.Today his books are read all over the world and his speaking events are watched by thousands on every continent. Why? Because what he has been so derided for saying is now happening in world events and even mainstream scientists are concluding that reality is indeed a simulation.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-dark text-white" id="download">
          <div className="container text-center">
            <h2 className="mb-4">Alex Jones Interviews David Icke</h2>
            <h5 className="mb-4">2020-03-26</h5>
            <p><a className="btn btn-light btn-xl" href='/20200326_Alex_Jones_interviews_David_Icke.mp3'>Download mp3</a></p>
            <p><a className="btn btn-light btn-xl" href='/20200326_Alex_Jones_interviews_David_Icke.ogg'>Download ogg</a></p>
            <p><a className="btn btn-light btn-xl" href='http://rss.infowars.com/20200326_Thu_Alex.mp3'>Download original InfoWars Show</a></p>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Want more great interviews?</h2>
                <hr className="divider my-4"/>
                <p className="text-muted mb-5"> Check us out on <a href='https://enterview.rocks/'>EnterView.Rocks</a></p>
              </div>
            </div>
          </div>
        </section>
        <PortfolioModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
          <PortfolioCarousel images={this.props.data.images.edges} current={this.state.modalCurrent}/>
        </PortfolioModal>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
